import { PlainObject } from 'shared/util/types.util'
import Tracker from 'analytics/OmniTracker/Tracker'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import singleton from 'shared/util/singleton.util'

export interface LocalEvent {
  id: string
  name: string
  time: DateTime
  payload: PlainObject
}

export type ChangeListener = (event: LocalEvent) => void

class LocalTracker implements Tracker {
  private enabled = false

  private listeners: ChangeListener[] = []

  trackEvent(name: string, payload: PlainObject): void {
    if (this.enabled) {
      const event: LocalEvent = {
        id: uuid(), name, time: DateTime.now(), payload,
      }
      this.listeners.forEach((listener) => listener(event))
    }
  }

  resetSession(): void {
    // No-op
  }

  enable(enabled = true): void {
    this.enabled = enabled
  }

  isEnabled(): boolean {
    return this.enabled
  }

  addListener(listener: ChangeListener): void {
    this.listeners.push(listener)
  }

  removeListener(listener: ChangeListener): void {
    this.listeners = this.listeners.filter((l) => l !== listener)
  }
}

// Singleton export, there can only ever be one LocalTracker
export default singleton('LocalTracker', () => new LocalTracker())
